import React, { useState } from "react";
import { QueryBuilderAntD } from "@react-querybuilder/antd";
import { QueryBuilder, formatQuery } from "react-querybuilder";
import { Button, message } from "antd";
import { Container, Box } from "@lib/Layout";
import CustomValueEditor from "./CustomValueEditor";

const fields = [
  {
    name: "signupdate",
    label: "Signup date",
    valueEditorType: "custom",
    operators: [
      { name: "=", label: "=" },
      { name: "!=", label: "!=" },
      { name: "<", label: "<" },
      { name: ">", label: ">" },
      { name: "<=", label: "<=" },
      { name: ">=", label: ">=" },
      { name: "between", label: "between" },
    ],
  },
  {
    name: "notes",
    label: "Notes",
    valueEditorType: "custom",
    operators: [
      { name: "=", label: "=" },
      { name: "!=", label: "!=" },
      { name: "<", label: "<" },
      { name: ">", label: ">" },
      { name: "<=", label: "<=" },
      { name: ">=", label: ">=" },
      { name: "between", label: "between" },
    ],
  },
  {
    name: "callbacks",
    label: "Callbacks",
    valueEditorType: "custom",
    operators: [
      { name: "=", label: "=" },
      { name: "!=", label: "!=" },
      { name: "<", label: "<" },
      { name: ">", label: ">" },
      { name: "<=", label: "<=" },
      { name: ">=", label: ">=" },
      { name: "between", label: "between" },
    ],
  },
  {
    name: "Purchase",
    label: "Purchase",
    valueEditorType: "custom",
    operators: [
      { name: "=", label: "=" },
      { name: "!=", label: "!=" },
      { name: "<", label: "<" },
      { name: ">", label: ">" },
      { name: "<=", label: "<=" },
      { name: ">=", label: ">=" },
      { name: "between", label: "between" },
    ],
  },
  {
    name: "count",
    label: "Count",
    valueEditorType: "select",
    values: [
      { name: "performed", label: "Performed" },
      { name: "notPerformed", label: "Not Performed" },
    ],
    operators: [
      { name: "=", label: "=" },
      { name: "!=", label: "!=" },
    ],
  },
  {
    name: "quote",
    label: "Quote",
    valueEditorType: "custom",
    operators: [
      { name: "=", label: "=" },
      { name: "!=", label: "!=" },
      { name: "<", label: "<" },
      { name: ">", label: ">" },
      { name: "<=", label: "<=" },
      { name: ">=", label: ">=" },
      { name: "between", label: "between" },
    ],
  },
  {
    name: "list",
    label: "List",
    valueEditorType: "custom",
    operators: [
      { name: "=", label: "=" },
      { name: "!=", label: "!=" },
      { name: "<", label: "<" },
      { name: ">", label: ">" },
      { name: "<=", label: "<=" },
      { name: ">=", label: ">=" },
      { name: "between", label: "between" },
    ],
  },
  {
    name: "amountSpent",
    label: "Amount Spent",
    valueEditorType: "select",
    values: [
      { name: "notSpent", label: "Not spent" },
      { name: "$500", label: "$500" },
      { name: "$1000", label: "$1000" },
      { name: "$2000", label: "$2000" },
      { name: "$5000", label: "$5000" },
      { name: "$10000", label: "$10000" },
    ],
    operators: [
      { name: "=", label: "=" },
      { name: "!=", label: "!=" },
      { name: "<", label: "<" },
      { name: ">", label: ">" },
      { name: "<=", label: "<=" },
      { name: ">=", label: ">=" },
      { name: "between", label: "between" },
    ],
  },
];

const EmailCreateRule = () => {
  const currentDate = new Date().toISOString().split("T")[0];

  const initialQuery = {
    rules: [
      {
        field: "signupdate",
        operator: "=",
        value: currentDate,
      },
    ],
  };

  const [query, setQuery] = useState(initialQuery);
  const [ruleName, setRuleName] = useState("");
  const [liveQuery, setLiveQuery] = useState("");
  const [loading, setLoading] = useState(false);

  const handleQueryChange = (newQuery) => {
    setQuery(newQuery);
    setLiveQuery(formatQuery(newQuery, "sql"));
  };

  const sql = formatQuery(query, "sql");
  console.log(sql, "sql");

  const handleSubmit = async () => {
    if (!ruleName) {
      message.error("Please enter a rule name before saving.");
      return;
    }

    const loginData = JSON.parse(localStorage.getItem("logindata"));
    const token = loginData ? loginData.accessToken : null;
    const userId = loginData ? loginData.userId : "";

    const requestData = {
      UserId: userId,
      RuleName: ruleName,
      RuleCriteria: liveQuery,
    };

    setLoading(true);
    try {
      const response = await fetch(
        "https://usfarmdata.org/fastapi/email_function/?operation=createrule",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(requestData),
        }
      );

      if (response.ok) {
        const result = await response.json();
        message.success("Rule saved successfully!");
        console.log("Success:", result);
      } else {
        throw new Error("Failed to save the rule.");
      }
    } catch (error) {
      message.error("Error saving the rule. Please try again.");
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container className="gap-2 bg-white p-2">
      <div
        className="p-2 rounded-md bg-white"
        style={{
          boxShadow:
            "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
        }}
      >
        Create Rules
      </div>
      <Box
        flexible
        scroll="vertical"
        style={{
          boxShadow:
            "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
        }}
        className="rounded-md bg-white p-3"
      >
        <div
          className="mt-3 mb-4 w-96 p-2 rounded-md"
          style={{
            boxShadow:
              "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
          }}
        >
          <label
            htmlFor="rule_name"
            className="block mb-1 text-sm font-medium text-gray-900"
          >
            Rule Name
          </label>
          <input
            type="text"
            id="rule_name"
            className="border-b border-gray-300 focus:border-blue-500 text-gray-900 text-sm block w-full py-3 outline-none"
            placeholder="Enter rule name..."
            value={ruleName}
            onChange={(e) => setRuleName(e.target.value)}
            required
          />
        </div>

        <QueryBuilderAntD>
          <QueryBuilder
            fields={fields}
            query={query}
            onQueryChange={handleQueryChange}
            showCloneButtons
            showIndependentCombinators
            showBranches
            controlClassnames={{
              queryBuilder: "queryBuilder-branches",
            }}
            controlElements={{ valueEditor: CustomValueEditor }}
          />
        </QueryBuilderAntD>
        <div
          className="mt-3 mb-4 w-96 p-2 rounded-md"
          style={{
            boxShadow:
              "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
          }}
        >
          <h4 className="font-medium">Live SQL Query:</h4>
          <pre className="text-gray-700 whitespace-pre-wrap">{liveQuery}</pre>
        </div>
      </Box>

      <div
        className="bg-white p-2 rounded-md"
        style={{
          boxShadow:
            "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
        }}
      >
        <Button
          type="primary"
          onClick={handleSubmit}
          style={{ backgroundColor: "#3B82F6", borderColor: "#3B82F6" }}
          loading={loading}
        >
          {loading ? "Saving rule..." : "Save Rule"}
        </Button>
      </div>
    </Container>
  );
};

export default EmailCreateRule;
