import React, { useState } from "react";
import { Container, Box } from "@lib/Layout";
import {
  Button,
  Form,
  Select,
  Input,
  DatePicker,
  TimePicker,
  Row,
  Col,
} from "antd";
import dayjs from "dayjs";

const Schedule = () => {
  const [activeTab, setActiveTab] = useState("history");
  const [form] = Form.useForm();
  const { Option } = Select;
  const handleSubmit = (values) => {
    console.log("Form Values:", values);
  };

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <Container alignBox="row" className="gap-2">
      <Box
        flexible
        className="rounded-md bg-white"
        style={{
          boxShadow:
            "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
        }}
      >
        <Container className="gap-2">
          <div className="p-2 bg-white rounded-md">
            {/* Tabs */}
            <div className="flex border-b gap-4">
              <button
                className={`p-2 cursor-pointer transition-all duration-300 ease-in-out ${
                  activeTab === "history"
                    ? "border-b-2 border-blue-500 font-semibold text-slate-700"
                    : "border-b-2 border-transparent text-gray-500"
                }`}
                onClick={() => handleTabClick("history")}
              >
                Schedule History
              </button>
              <button
                className={`p-2 cursor-pointer transition-all duration-300 ease-in-out ${
                  activeTab === "add"
                    ? "border-b-2 border-blue-500 font-semibold  text-slate-700"
                    : "border-b-2 border-transparent text-gray-500"
                }`}
                onClick={() => handleTabClick("add")}
              >
                Add New Schedule
              </button>
            </div>
          </div>

          <Box flexible>
            {activeTab === "history" && (
              <div>
                <h3 className="font-bold">Scheduled History</h3>
                <p>Content related to scheduled history will go here.</p>
              </div>
            )}

            {activeTab === "add" && (
              <Container className="gap-2">
                <Box flexible scroll="vertical" className="p-2">
                  <Container alignBox="row" className="gap-3 w-full">
                    <Form
                      form={form}
                      layout="vertical"
                      onFinish={handleSubmit}
                      initialValues={{
                        scheduleDate: dayjs(),
                        scheduleTime: dayjs(),
                      }}
                    >
                      <Row gutter={16}>
                        <Col span={12}>
                          <Form.Item
                            label="Select Rule"
                            name="rule"
                            rules={[
                              {
                                required: true,
                                message: "Please select a rule",
                              },
                            ]}
                          >
                            <Select
                              placeholder="Select Rule"
                              size="large"
                              style={{ width: 300 }}
                            >
                              <Option value="rule1">Rule 1</Option>
                              <Option value="rule2">Rule 2</Option>
                            </Select>
                          </Form.Item>
                        </Col>

                        <Col span={12}>
                          <Form.Item
                            label="Select Email Template"
                            name="emailTemplate"
                            rules={[
                              {
                                required: true,
                                message: "Please select an email template",
                              },
                            ]}
                          >
                            <Select
                              placeholder="Select Email Template"
                              size="large"
                              style={{ width: 300 }}
                            >
                              <Option value="template1">Template 1</Option>
                              <Option value="template2">Template 2</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row gutter={16}>
                        <Col span={12}>
                          <Form.Item
                            label="Email Subject"
                            name="emailSubject"
                            rules={[
                              {
                                required: true,
                                message: "Please enter email subject",
                              },
                            ]}
                          >
                            <Input
                              placeholder="Email Subject"
                              size="large"
                              style={{ width: 300 }}
                            />
                          </Form.Item>
                        </Col>

                        <Col span={12}>
                          <Form.Item
                            label="From Name"
                            name="fromName"
                            rules={[
                              {
                                required: true,
                                message: "Please enter from name",
                              },
                            ]}
                          >
                            <Input
                              placeholder="From Name"
                              size="large"
                              style={{ width: 300 }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row gutter={16}>
                        <Col span={12}>
                          <Form.Item
                            label="From Email"
                            name="fromEmail"
                            rules={[
                              {
                                required: true,
                                message: "Please enter from email",
                              },
                              {
                                type: "email",
                                message: "Please enter a valid email",
                              },
                            ]}
                          >
                            <Input
                              placeholder="From Email"
                              size="large"
                              style={{ width: 300 }}
                            />
                          </Form.Item>
                        </Col>

                        <Col span={12}>
                          <Form.Item
                            label="Schedule Name"
                            name="scheduleName"
                            rules={[
                              {
                                required: true,
                                message: "Please enter schedule name",
                              },
                            ]}
                          >
                            <Input
                              placeholder="Schedule Name"
                              size="large"
                              style={{ width: 300 }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row gutter={16}>
                        <Col span={12}>
                          <Form.Item
                            label="Schedule Date"
                            name="scheduleDate"
                            rules={[
                              {
                                required: true,
                                message: "Please select a date",
                              },
                            ]}
                          >
                            <DatePicker
                              className="w-full"
                              size="large"
                              style={{ width: 300 }}
                            />
                          </Form.Item>
                        </Col>

                        <Col span={12}>
                          <Form.Item
                            label="Schedule Time"
                            name="scheduleTime"
                            rules={[
                              {
                                required: true,
                                message: "Please select a time",
                              },
                            ]}
                          >
                            <TimePicker
                              className="w-full"
                              size="large"
                              style={{ width: 300 }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form>
                  </Container>
                </Box>
                <div className="border-t p-2 bg-white">
                  <Button
                    type="primary"
                    size="midium"
                    onClick={() => form.submit()}
                  >
                    Add New Schedule
                  </Button>
                </div>
              </Container>
            )}
          </Box>
        </Container>
      </Box>
    </Container>
  );
};

export default Schedule;
