import React from "react";
import "./App.css";
import AppContainer from "./AppContainer";
import { LoginProvider } from "./context/LoginContext";
import { RolesProvider } from "./context/RoleContext";
import { FontSizeProvider } from "./context/FontSizeContext";
import GmailProvider from "./context/GmailContext";
function App() {
  return (
    <LoginProvider>
      <FontSizeProvider>
        <div className="h-screen">
          <RolesProvider>
            <GmailProvider>
              <AppContainer />
            </GmailProvider>
          </RolesProvider>
        </div>
      </FontSizeProvider>
    </LoginProvider>
  );
}

export default App;
