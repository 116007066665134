import React, { useState } from "react";
import { Select, DatePicker } from "antd";
import { AntDValueEditor } from "@react-querybuilder/antd";
import dayjs from "dayjs";

const { RangePicker } = DatePicker;

const CustomValueEditor = (props) => {
  const fieldName = props.fieldData?.name;
  const operator = props.operator;
  const [nestedValue, setNestedValue] = useState(null);
  const [dateRange, setDateRange] = useState([]);

  const rangePresets = [
    {
      label: "Last 7 Days",
      value: [dayjs().subtract(7, "day"), dayjs()],
    },
    {
      label: "Last 14 Days",
      value: [dayjs().subtract(14, "day"), dayjs()],
    },
    {
      label: "Last 1 Month",
      value: [dayjs().subtract(1, "month"), dayjs()],
    },
    {
      label: "Last 3 Months",
      value: [dayjs().subtract(3, "month"), dayjs()],
    },
    {
      label: "Last 6 Months",
      value: [dayjs().subtract(6, "month"), dayjs()],
    },
    {
      label: "Last 9 Months",
      value: [dayjs().subtract(9, "month"), dayjs()],
    },
    {
      label: "Last 1 Year",
      value: [dayjs().subtract(1, "year"), dayjs()],
    },
  ];

  const handleDateChange = (dates, dateStrings) => {
    setDateRange(dateStrings);
    props.handleOnChange(dateStrings);
  };

  const handleSingleDateChange = (date, dateString) => {
    props.handleOnChange(dateString);
  };

  const handlePurchaseChange = (value) => {
    props.handleOnChange(value);
    setNestedValue(null);
    setDateRange([]);
  };

  const singlePurchaseDateChange = (date, dateString) => {
    const purchaseValue = props.value || "";
    const combinedValue = dateString
      ? `${purchaseValue}_${dateString}`
      : purchaseValue;
    props.handleOnChange(combinedValue);
  };

  const handlePurchaseDateChange = (dates, dateStrings) => {
    setDateRange(dateStrings);
    const purchaseValue = props.value;
    const combinedValue = dates
      ? `${purchaseValue}_${dateStrings.join(",")}`
      : purchaseValue;
    props.handleOnChange(combinedValue);
  };

  const handleNotesChange = (value) => {
    setNestedValue(value);
    if (value === "noNotes") {
      setDateRange([]);
      props.handleOnChange(value);
    } else {
      props.handleOnChange("");
    }
  };

  const purchaseOptions = {
    noPurchase: "No Purchase",
    goLeads: "GoLeads",
    usfd: "USFD",
    lgc: "LGC",
  };

  if (fieldName === "Purchase") {
    return (
      <div className="flex gap-2">
        <Select
          value={props.value || "Select Purchase Option"}
          onChange={handlePurchaseChange}
          style={{ width: "200px" }}
          placeholder="Select Purchase Option"
        >
          <Select.Option value="noPurchase">
            {purchaseOptions.noPurchase}
          </Select.Option>
          <Select.Option value="goLeads">
            {purchaseOptions.goLeads}
          </Select.Option>
          <Select.Option value="usfd">{purchaseOptions.usfd}</Select.Option>
          <Select.Option value="lgc">{purchaseOptions.lgc}</Select.Option>
        </Select>

        {["goLeads", "usfd", "lgc"].includes(props.value) &&
          (operator === "between" ? (
            <RangePicker
              onChange={handlePurchaseDateChange}
              presets={rangePresets}
              style={{ width: "300px" }}
            />
          ) : (
            <DatePicker
              onChange={singlePurchaseDateChange}
              style={{ width: "200px" }}
            />
          ))}
      </div>
    );
  }

  if (fieldName === "notes") {
    return (
      <div className="flex gap-2">
        <Select
          value={nestedValue}
          onChange={handleNotesChange}
          style={{ width: "200px" }}
          placeholder="Select Notes Option"
        >
          <Select.Option value="noNotes">No Notes</Select.Option>
          <Select.Option value="chooseDate">Select Custom Date</Select.Option>
        </Select>

        {nestedValue === "chooseDate" &&
          (operator === "between" ? (
            <RangePicker
              presets={rangePresets}
              onChange={handleDateChange}
              style={{ width: "300px" }}
            />
          ) : (
            <DatePicker
              onChange={handleSingleDateChange}
              style={{ width: "200px" }}
            />
          ))}
      </div>
    );
  }

  if (["signupdate", "callbacks", "quote", "list"].includes(fieldName)) {
    return (
      <div className="flex gap-2">
        {operator === "between" || operator === "notBetween" ? (
          <RangePicker
            presets={rangePresets}
            onChange={handleDateChange}
            style={{ width: "300px" }}
          />
        ) : (
          <DatePicker
            onChange={handleSingleDateChange}
            style={{ width: "200px" }}
          />
        )}
      </div>
    );
  }

  return <AntDValueEditor {...props} />;
};

export default CustomValueEditor;
